import REQUEST from "../../services/Request";

export const GET_PAYMENT_LOGS = async (data) => {
  return await REQUEST({
    method: "post",
    url: "Payments/filter",
    data: data,
  });
};
export const EDIT_PAYMENT = async (data) => {
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "Payments",
    data,
  });
};

export const DELETE_PAYMENT = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Payments/" + id,
  });
};
