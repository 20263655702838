import { useTranslation } from "react-i18next";
import PageLayout from "../../components/PageLayout/PageLayout";
import StoreRequests from "../StoreData/Requests/StoreRequests";
const Transactions = () => {
  const { t, i18n } = useTranslation();
  return (
    <PageLayout
    // title={"Store Elements"}
    // loading={loading}
    // onHiding={() => setLoading(false)}
    >
      <StoreRequests hasCommon={true} />
    </PageLayout>
  );
};

export default Transactions;
