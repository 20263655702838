import CrudMUI from "app/components/CrudTable/CrudMUI";
import useAuth from "app/hooks/useAuth";
import { columnPayments, paymentTypes } from "app/services/SharedData";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "../../components/PageLayout/PageLayout";
import { DELETE_PAYMENT, EDIT_PAYMENT, GET_PAYMENT_LOGS } from "./Api";
const Payments = () => {
  const [values, setValues] = useState({});
  const { t, i18n } = useTranslation();
  const lookups = useAuth().lookups;
  const onToolbarPreparing = (toolbarItems) => {
    toolbarItems.unshift({
      widget: "dxSelectBox",
      location: "after",
      options: {
        dataSource: paymentTypes,
        placeholder: t("Type"),
        displayExpr: i18n.language == "en" ? "NameEn" : "Name",
        valueExpr: "Id",
        name: "Type",
        value: values["Type"],
        onValueChanged: (e) => {
          setValues((prev) => ({ ...prev, ["Type"]: e.value }));
        },
      },
    });
    toolbarItems.unshift({
      widget: "dxDateBox",
      location: "after",
      options: {
        width: 120,
        placeholder: t("To"),
        displayFormat: "yyyy/MM/dd",
        value: values["To"],
        onValueChanged: (e) => {
          setValues((prev) => ({ ...prev, ["To"]: e.value }));
        },
      },
    });
    toolbarItems.unshift({
      widget: "dxDateBox",
      location: "after",
      options: {
        width: 120,
        placeholder: t("From"),
        displayFormat: "yyyy/MM/dd",
        value: values["From"],
        onValueChanged: (e) => {
          setValues((prev) => ({ ...prev, ["From"]: e.value }));
        },
      },
    });

    // "To" date input
  };
  return (
    <PageLayout>
      <CrudMUI
        id={"Id"}
        colAttributes={columnPayments}
        EDIT={EDIT_PAYMENT}
        ADD={EDIT_PAYMENT}
        DELETE={DELETE_PAYMENT}
        GET={GET_PAYMENT_LOGS}
        onToolbarPreparing={onToolbarPreparing}
      />
      {/* <MasterTable
        apiMethod={GET_PAYMENT_LOGS}
        apiPayload={values}
        apiKey={"Id"}
        remoteOperations={true}
        colAttributes={columnPayments}
        onToolbarPreparing={onToolbarPreparing}
      /> */}
    </PageLayout>
  );
};

export default Payments;
