import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "./components/Loadable";
//import Users from "./pages/Users/Users"
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import Payments from "./pages/Payments/Payments";
import Transactions from "./pages/Transactions/Transactions";

const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const Accessories = Loadable(
  lazy(() => import("./pages/Accessories/Accessories"))
);
const Roles = Loadable(lazy(() => import("./pages/Roles/Roles")));
const GroupsActions = Loadable(
  lazy(() => import("./pages/Actions/GroupsActions"))
);
const ProductionState = Loadable(
  lazy(() => import("./pages/ProductionState/ProductionState"))
);
const RequestList = Loadable(
  lazy(() => import("./pages/RequestList/RequestList"))
);
const StoreLogs = Loadable(lazy(() => import("./pages/StoreData/StoreLogs")));
const StoreElements = Loadable(
  lazy(() => import("./pages/StoreElements/StoreElements"))
);
const Stores = Loadable(lazy(() => import("./pages/Stores/Stores")));
const Suppliers = Loadable(lazy(() => import("./pages/Suppliers/Suppliers")));
const Customers = Loadable(lazy(() => import("./pages/Customers/Customers")));
const Users = Loadable(lazy(() => import("./pages/Users/Users")));
const Items = Loadable(lazy(() => import("./pages/Items/Items")));

const Sizes = Loadable(lazy(() => import("./pages/Sizes/Sizes")));
const Stages = Loadable(lazy(() => import("./pages/Stages/Stages")));
const Categories = Loadable(
  lazy(() => import("./pages/Categories/Categories"))
);
const Colors = Loadable(lazy(() => import("./pages/Colors/Colors")));
const DescriptionColors = Loadable(
  lazy(() => import("./pages/DescriptionColor/DescriptionColors"))
);
const Groups = Loadable(lazy(() => import("./pages/Groups/Groups")));
const NeedPurchase = Loadable(
  lazy(() => import("./pages/NeedPurchase/NeedPurchase"))
);
const Order = Loadable(lazy(() => import("./pages/Order/Order")));
const POSPage = Loadable(lazy(() => import("./pages/POS/POSPage")));
const Planning = Loadable(lazy(() => import("./pages/Planning/Planning")));
const Recipe = Loadable(lazy(() => import("./pages/Recipe/Recipe")));

// export const navigationsx = [
//   { name: "items", path: "/dashboard/items", icon: "items" },
//   { name: "Dashboard", path: "/dashboard/default", icon: "dashboard" },
//   { label: "PAGES", type: "label" },
//   {
//     name: "Session/Auth",
//     icon: "security",
//     children: [
//       { name: "Sign in", iconText: "SI", path: "/session/signin" },
//       { name: "Sign up", iconText: "SU", path: "/session/signup" },

//       {
//         name: "Forgot Password",
//         iconText: "FP",
//         path: "/session/forgot-password",
//       },
//       { name: "Error", iconText: "404", path: "/session/404" },
//     ],
//   },
//   { label: "elements", type: "label" },
//   {
//     name: "elements",
//     icon: "favorite",
//     badge: { value: "30+", color: "secondary" },
//     children: [
//       { name: "Auto Complete", path: "/material/autocomplete", iconText: "A" },
//       { name: "Buttons", path: "/material/buttons", iconText: "B" },
//       { name: "Checkbox", path: "/material/checkbox", iconText: "C" },
//       { name: "Dialog", path: "/material/dialog", iconText: "D" },
//       {
//         name: "Expansion Panel",
//         path: "/material/expansion-panel",
//         iconText: "E",
//       },
//       { name: "Form", path: "/material/form", iconText: "F" },
//       { name: "Icons", path: "/material/icons", iconText: "I" },
//       { name: "Menu", path: "/material/menu", iconText: "M" },
//       { name: "Progress", path: "/material/progress", iconText: "P" },
//       { name: "Radio", path: "/material/radio", iconText: "R" },
//       { name: "Switch", path: "/material/switch", iconText: "S" },
//       { name: "Slider", path: "/material/slider", iconText: "S" },
//       { name: "Snackbar", path: "/material/snackbar", iconText: "S" },
//       { name: "Table", path: "/material/table", iconText: "T" },
//     ],
//   },
//   {
//     name: "Charts",
//     icon: "trending_up",
//     children: [{ name: "Echarts", path: "/charts/echarts", iconText: "E" }],
//   },
//   // {
//   //   name: 'Documentation',
//   //   icon: 'launch',
//   //   type: 'extLink',
//   //   path: 'http://demos.ui-lib.com/matx-react-doc/'
//   // }
// ];

export const category = [
  {
    Id: 7,
    name: "Reports",
    path: "/reports",
    icon: "assessment",
    badge: { value: "30+", color: "secondary" },
  },
  {
    Id: 1,
    name: "Inventory",
    path: "/inventory",
    icon: "device_hub",
    badge: { value: "30+", color: "secondary" },
  },
  // {
  //   Id: 2,
  //   name: "Identifications",
  //   path: "/identifications",
  //   icon: "dashboard",
  //   badge: { value: "30+", color: "secondary" },
  // },
  {
    Id: 3,
    name: "Inventory",
    path: "/inventory",
    icon: "format_line_spacing",
    badge: { value: "30+", color: "secondary" },
  },
  // {
  //   Id: 4,
  //   name: "Design",
  //   path: "/design",
  //   icon: "brush",
  //   badge: { value: "30+", color: "secondary" },
  // },

  {
    Id: 5,
    name: "POS",
    path: "/pos",
    icon: "local_grocery_store",
    badge: { value: "30+", color: "secondary" },
  },
  {
    Id: 0,
    name: "System",
    icon: "build",
    path: "/system",
    badge: { value: "30+", color: "secondary" },
  },
];
export const pages = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/dashboard/default",
        element: <Analytics />,
        auth: authRoles.admin,
      },
      {
        name: "Sub-Category",
        iconText: "B",
        path: "/sub-category",
        key: "ItemsView",
        categoryId: 0,
        element: <Items />,
        // badge: { value: "30+", color: "secondary" },
      },
      {
        name: "Categories",
        path: "/categories",
        key: "CategoriesView",
        iconText: "B",

        categoryId: 0,
        element: <Categories />,
      },
      {
        name: "Suppliers",
        path: "/suppliers",

        key: "SuppliersView",
        iconText: "B",
        categoryId: 0,
        element: <Suppliers />,
      },
      {
        name: "Customer",
        path: "/customer",

        key: "CustomersView",
        iconText: "B",
        categoryId: 0,
        element: <Customers />,
      },
      {
        name: "Countries",
        path: "/countries",
        key: "ConfigurationsView",
        categoryId: 0,
        element: <Colors />,
        iconText: "B",
      },
      {
        name: "Items",
        path: "/items",
        key: "ItemsView",
        categoryId: 3,
        iconText: "B",
        element: <StoreElements />,
      },
      {
        name: "Transactions",
        path: "/transactions",
        key: "TransactionsView",
        iconText: "B",
        categoryId: 3,
        element: <Transactions />,
      },
      {
        name: "Purchase Requests",
        path: "/purchase-requests",
        key: "NeedPurchaseView",
        iconText: "B",
        categoryId: 3,
        element: <NeedPurchase />,
      },
      {
        name: "Store",
        key: "StoresView",
        path: "/system/store",
        iconText: "B",
        categoryId: 0,
        element: <Stores />,
      },
      {
        name: "Users",
        key: "UsersView",
        path: "/system/users",
        iconText: "B",
        categoryId: 0,
        element: <Users />,
      },
      {
        name: "POS",
        path: "/pos",
        iconText: "B",
        categoryId: 5,
        key: "POSView",
        element: <POSPage />,
      },
      {
        name: "Invoices",
        path: "/Invoices",
        iconText: "B",
        categoryId: 5,
        key: "InvoicesView",
        element: <RequestList />,
      },
      {
        name: "Payments",
        path: "/payments",
        iconText: "B",
        categoryId: 3,
        key: "PaymentsView",
        element: <Payments />,
      },
    ],
  },
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> },
];
function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}
const load_pages = (roles) => {
  const push = [];
  const validPages = pages[0].children.filter((e) =>
    roles.find((role) => role === e.key)
  );

  for (const property in groupByKey(validPages, "categoryId")) {
    if (category.find((e) => e.Id == property))
      push.push({
        name: category.find((e) => e.Id == property).name,
        icon: category.find((e) => e.Id == property).icon, // badge: { value: "30+", color: "secondary" },
        children: groupByKey(validPages, "categoryId")[property],
      });
  }
  return [
    {
      element: <Analytics />,
      auth: authRoles.admin,
      name: "Dashboard",
      path: "/dashboard/default",
      icon: "dashboard",
    },
    { label: "PAGES", type: "label" },
    ...push,
  ];
};

export const navigations = load_pages;
