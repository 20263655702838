import REQUEST from "../../services/Request";
import { mapArrayObject } from "../../services/SharedData";

export const GET_LOGS = async (data) => {
  return await REQUEST({
    method: "post",
    url: "StoreLogs/filter",
    data: data,
  });
};
export const DELETE_LOGS = async (data) => {
  return await REQUEST({
    method: "delete",
    url: "StoreLogs/" + data.Id,
  });
};

export const SUBMIT = async (data, type) => {
  data.Data.map((e) => {
    // if (!e.SizeId) {
    //   e.SizeId = 0;
    // }
    // if (!e.DescriptionColorId) {
    //   e.DescriptionColorId = 0;
    // }
    // if (!e.ProductionOrderRequestId) {
    //   e.ProductionOrderRequestId = 0;
    // }
    if (!e.SupplierId) {
      e.SupplierId = 0;
    }
  });
  return await REQUEST({
    method: "post",
    url: "Invoices",
    data: mapArrayObject("", data),
  });
};

export const DELETE = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "StoreLogs/" + id,
  });
};
