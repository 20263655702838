import MasterTable from "app/components/masterTable/MasterTable";

import useAuth from "app/hooks/useAuth";
import { ActionType, columnAttributesElements } from "app/services/SharedData";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_LOGS } from "../Api";

export default function StoreRequests({ type, displayElementData = true }) {
  const { t, i18n } = useTranslation();
  const lookups = useAuth().lookups;
  const [col, setCol] = useState([]);
  const [values, setValues] = useState({});
  const onToolbarPreparing = (toolbarItems) => {
    // "To" date input
    toolbarItems.unshift({
      widget: "dxDateBox",
      location: "after",
      options: {
        width: 120,
        placeholder: t("To"),
        displayFormat: "yyyy/MM/dd",
        value: values["To"],
        onValueChanged: (e) => {
          setValues((prev) => ({ ...prev, ["To"]: e.value }));
        },
      },
    });
    toolbarItems.unshift({
      widget: "dxDateBox",
      location: "after",
      options: {
        width: 120,
        placeholder: t("From"),
        displayFormat: "yyyy/MM/dd",
        value: values["From"],
        onValueChanged: (e) => {
          setValues((prev) => ({ ...prev, ["From"]: e.value }));
        },
      },
    });
  };

  useEffect(() => {
    if (type) setValues((prev) => ({ ...prev, Type: type }));
  }, [type]);
  useEffect(() => {
    const elementData = !displayElementData
      ? []
      : columnAttributesElements(lookups, i18n);
    const realcols =
      lookups != null
        ? [
            {
              caption: "InvoiceId",
              field: "StoreLogInvoiceId",
              captionEn: "InvoiceId",
            },
            {
              caption: "Date",
              field: "Date",
              captionEn: "Date",
              type: "datetime",
              format: "M/d/yyyy, HH:mm",
            },
            {
              caption: "Quantity",
              field: "Quantity",
              captionEn: "Quantity",
            },
            {
              caption: "Action",
              field: "IsIn",
              captionEn: "Action",
              data: ActionType,
              display: "Name",
              displayEn: "NameEn",
              value: "Id",
            },
            {
              caption: "Balance",
              field: "Balance",
              captionEn: "Balance",
            },
            {
              caption: "Note",
              field: "Note",
              captionEn: "Note",
            },
            ...elementData,
          ]
        : [];
    setCol(
      lookups?.Roles?.find((role) => role == "StoreLogsCost") != null
        ? realcols
        : realcols.filter((e) => !["Cost", "TotalCost"].includes(e.field))
    );
  }, [lookups]);

  return (
    <>
      <div>
        <MasterTable
          apiMethod={GET_LOGS}
          apiPayload={values}
          apiKey={"StoreLogId"}
          colAttributes={col}
          remoteOperations={true}
          onToolbarPreparing={onToolbarPreparing}
          searchPanel={false}
        />
      </div>
    </>
  );
}
